import { createRouter, createWebHistory } from "vue-router";
import { $i18N } from "@/utility/util.js";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { i18n_title: "pgtitle.home" },
    component: () => import(/* webpackChunkName: "Home" */ "@/pages/Home.vue"),
  },
  {
    path: "/into-cloud",
    name: "Cloud Service",
    meta: { i18n_title: "pgtitle.into_cloud" },
    component: () => import("@/pages/CloudService.vue"),
  },
  {
    path: "/cyber-security",
    name: "Cyber Service",
    meta: { i18n_title: "pgtitle.cyber_security" },
    component: () => import("@/pages/CyberService.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { i18n_title: "pgtitle.notfound" },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/pages/NotFound.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    meta: { i18n_title: "pgtitle.pricing" },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/pages/Pricing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to) => {
  let {
    meta: { i18n_title = "pgtitle.home" },
  } = to;
  document.title = $i18N(i18n_title);
});

export default router;
