import { createStore } from "vuex";
import en from "@/locales/en.json";
import link from "@/constants/link.json";
import info from "@/constants/info.json";

let store = createStore({
  debug: true,
  state: {
    i18n: en,
    link,
    info,
  },
  actions: {},
  mutations: {},
  getters: {},
  modules: {},
});

export default store;
